export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

/**
 * Generates a short name from the first letters of each word in a string.
 * @param {string} name - The full name to be shortened.
 * @returns {string} - The short name.
 */
export function getShortName(name) {
  return name
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join('')
}

export function formatURL(url) {
  return url.startsWith('/') ? url : '/' + url
}
