import CustomLink from '@shared/components/customLink'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styles from './../style.module.scss'
import useWindowSize from '@shared/hooks/windowSize'
import { formatURL } from '@shared/constants/utils'

function CDropdown({ data }) {
  const [show, setShow] = useState(false)
  const [width] = useWindowSize()

  useEffect(() => {
    if (width < 1024) {
      function onScrollMenu(e) {
        setShow(false)
      }
      window.addEventListener('click', e => {
        // Close when user click on outside
        const element = document.getElementById(`uniq${data?._id}`)
        if (!element?.contains(e.target)) {
          setShow(false)
        }
      })

      window.addEventListener('scroll', onScrollMenu, { passive: true })
      return () => window.removeEventListener('scroll', onScrollMenu)
    }
  }, [width])
  return (
    <>
      {data?.oChildren?.map(c => {
        return (
          <CustomLink href={formatURL(c?.sSlug)} prefetch={false} key={`d-none${c?._id}`}>
            <a className="d-none">{c?.sTitle}</a>
          </CustomLink>
        )
      })}
      <Dropdown
        show={show}
        className='mx-2'
        onMouseEnter={() => (width > 1024 ? setShow(true) : '')}
        onClick={() => (width < 1024 ? setShow(!show) : '')}
        onMouseLeave={() => (width > 1024 ? setShow(false) : '')}
        id={`uniq${data?._id}`}>
        <Dropdown.Toggle
          variant="link"
          id={data._id}
          className={`${styles.navMore} d-block`}
          >
          {data.sTitle}
        </Dropdown.Toggle>
        <Dropdown.Menu id={data._id} className={`${styles.dropdownMenu}`}>
          {data?.oChildren.map(c => {
            return (
              <CustomLink key={c?._id} href={formatURL(c?.sSlug)}>
                <a className="dropdown-item">{c?.sTitle}</a>
              </CustomLink>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

CDropdown.propTypes = {
  data: PropTypes.object
}

export default CDropdown
