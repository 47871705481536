import React from 'react'
import logo from '@assets/images/icon/oneturfLogo.svg'
import styles from './style.module.scss'
import dynamic from 'next/dynamic'

const MyImage = dynamic(() => import('@shared/components/myImage'))

const DownloadBanner = () => {
  return (<>
        <div className='d-md-none p-3 d-flex flex-row justify-content-between align-items-center w-100'>
            <div className='d-flex flex-row gap-2'>
                <MyImage src={logo} width={32} height={32} />
                <div className='d-flex flex-column'>
                    <span className={styles.t1}>Download the App</span>
                    <span className={styles.t2}>Get Fastest Score </span>
                </div>
            </div>
            <div>
                <a href="https://o2o.to/i/0MacsN" target="_blank" rel="noopener noreferrer nofollow">
                    <button className={styles.install}>Install</button>
                </a>
            </div>
        </div>
    </>
  )
}

export default DownloadBanner
